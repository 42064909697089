import { graphql } from 'gatsby';
import withLayout from '@/base/containers/withLayout';

import '@/base/styles/main.scss';
import NotFound from '@/components/templates/NotFound/NotFound';
import type { LocalizedNotFoundPageProps } from '@/components/templates/NotFound/NotFound.types';

export default function NotFoundPortuguese({
  data,
  location,
}: {
  data: LocalizedNotFoundPageProps;
  location: Location;
}) {
  const parsedPage = { ...data.page, content: JSON.parse(data.page.content) };

  return withLayout(NotFound, {
    blok: parsedPage,
    layout: data.layout,
    config: data.config,
    language: 'br',
    location,
  });
}

export const pageQuery = graphql`
  query Page404QueryBR {
    layout: storyblokEntry(full_slug: { eq: "br/_config/layout" }) {
      content
    }
    config: storyblokEntry(full_slug: { eq: "br/_config/config" }) {
      content
    }
    page: storyblokEntry(full_slug: { eq: "br/404" }) {
      content
      field_component
      full_slug
      id
      name
      parent_id
      slug
      uuid
    }
  }
`;
